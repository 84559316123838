import "./metaNavigation.css";
import t from '../../Utils/Translation';
import React, { useState, useEffect, useContext, useRef } from "react";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import { Link, useNavigate } from "react-router-dom";
import ActiveProfileImage from '../common/ActiveProfileImage';
import Image from "react-bootstrap/Image";
import NotificationsNavItem from './NotificationsNavItem';
import {
  FRONTEND_LOGIN_URL,
  CREATE_URL,
  LEADERBOARDS_URL,
  STORIES_URL,
  MESSAGES_URL,
  IDEOLOGY_URL,
  SETTINGS_URL,
  WELCOME_URL,
  SEARCH_URL,
  PLAYLISTS_URL,
  USER_MANAGEMENT_WITHOUTPARAM_URL,
  REPORTS_URL,
  CUSTOMIZE_LYKIO_URL,
  RESET_LEADERBOARDS_URL,
  AWARD_URL,
  PLAYLIST_URL,
  CONTENT_MANAGEMENT_CATEGORIES_URL,
  CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL,
  STRIPE_PAYMENT_URL,
  LIBRARY_URL_WITHOUT_PARAM,
  STORIES_FOLLOWING_URL,
  UBER_ADMIN_SETTINGS_URL,
  CREATE_ACCESS_CODE_URL,
  CONTENT_CREATION,
  CHAT_URL, CONNECTIONS_URL,
  LIBRARY_UNITS,
  LIBRARY_PROGRAMS,
  LIBRARY_RESOURCES, EDUCATIONAL_USER_MANAGEMENT_WITHOUTPARAM_URL, TT_REPORTING
} from "../../Utils/Constants";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {Badge, Button} from "@mui/material";
import SchoolIcon from '@mui/icons-material/SchoolSharp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsSharp';
import AppsIcon from '@mui/icons-material/AppsSharp';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearchSharp';
import LogoutIcon from '@mui/icons-material/LogoutSharp';
import ForumIcon from '@mui/icons-material/ForumSharp';
import HelpCenterIcon from '@mui/icons-material/HelpCenterSharp';
import BatchPredictionIcon from '@mui/icons-material/BatchPredictionSharp';
import PlayLessonIcon from '@mui/icons-material/PlayLessonSharp';
import SettingsIcon from '@mui/icons-material/SettingsSharp';
import AssessmentIcon from '@mui/icons-material/AssessmentSharp';
import CreditCardIcon from '@mui/icons-material/CreditCardSharp';
import StyleIcon from '@mui/icons-material/StyleSharp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccountsSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUploadSharp';
import NoteAltIcon from '@mui/icons-material/NoteAltSharp';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAddSharp';
import ArticleIcon from '@mui/icons-material/ArticleSharp';
import AddAPhotoSharpIcon from '@mui/icons-material/AddAPhotoSharp';
import EmailIcon from '@mui/icons-material/Email';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotosSharp';
import TableChartIcon from '@mui/icons-material/TableChartSharp';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAltSharp';
import QrCodeIcon from '@mui/icons-material/QrCodeSharp';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import FolderZipSharpIcon from '@mui/icons-material/FolderZipSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';
import QueuePlayNextSharpIcon from '@mui/icons-material/QueuePlayNextSharp';
import RotateLeftSharpIcon from '@mui/icons-material/RotateLeftSharp';
import ChatIcon from '@mui/icons-material/Chat';
import WorkspacesSharpIcon from '@mui/icons-material/WorkspacesSharp';
import ProfileNavItem from './ProfileNavItem';
import LibraryBooksSharpIcon from '@mui/icons-material/LibraryBooksSharp';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MenuBookSharpIcon from '@mui/icons-material/MenuBookSharp';


const MetaNavigation = (props) => {
  const context = useContext(LykioContext);
  const userPermissions = context.userPermissions;
  const categories = context.categories;
  const tenantProperties = context.tenantProperties;
  const userDetails = context.userDetails;
  const logo = tenantProperties && tenantProperties.forceRootTheme  ? tenantProperties.rootLogo : (tenantProperties && tenantProperties.logoS3Url) ? tenantProperties.logoS3Url : lykio_logo;

  const storiesName =  tenantProperties && tenantProperties.storiesName
  ? tenantProperties.storiesName
  : "Stories";
  const categoriesName =  tenantProperties && tenantProperties.categoriesName
  ? tenantProperties.categoriesName
  : "Categories";

  const showIdeology = tenantProperties && tenantProperties.showIdeology;
  // onst showGuides = tenantProperties && tenantProperties.showGuides;
  const showUserGuidesInMainNav = tenantProperties && tenantProperties.showUserGuidesInMainNav;
  const showMessages = tenantProperties && tenantProperties.showMessages;
  const showResetLeaderboards = tenantProperties && tenantProperties.showResetLeaderboards;

  const enableSchools = tenantProperties && tenantProperties.enableSchools;
  const showCategories = tenantProperties && tenantProperties.showCategories
  const showStories = tenantProperties && tenantProperties.showStories
  const showLeaderboards = tenantProperties && tenantProperties.showLeaderboards
  const showContentManagement = tenantProperties && tenantProperties.showContentManagement
  const showFeed = tenantProperties && tenantProperties.showFeed
  const showSearch = tenantProperties && tenantProperties.showSearch
  const showNotifications = tenantProperties && tenantProperties.showNotifications
  const showMessenger = tenantProperties && tenantProperties.showMessenger
  const showGuides = tenantProperties && tenantProperties.showGuides
  const showReports = tenantProperties && tenantProperties.showReports
  const showAwardsManagement = tenantProperties && tenantProperties.showAwardsManagement
  const showCustomizeLykio = tenantProperties && tenantProperties.showCustomizeLykio
  const showUserManagement = tenantProperties && tenantProperties.showUserManagement
  const showUberUserManagement = tenantProperties && tenantProperties.showUberUserManagement
  const showCreateContent = tenantProperties && tenantProperties.showCreateContent
  const showProfileProgress = tenantProperties && tenantProperties.showProfileProgress
  const showSchoolsReporting = tenantProperties && tenantProperties.showSchoolsReporting
  const showSchoolsUserManagement = tenantProperties && tenantProperties.showSchoolsUserManagement
  const showConnections = tenantProperties && tenantProperties.showConnections
  const showInnerGamesDownloadPage = tenantProperties && tenantProperties.showInnerGamesDownloadPage
  const activeAccessCodes = context.userDetails ? context.userDetails.activeAccessCodes : [];

  
  const navigate = useNavigate();
  const googleSSO = localStorage.getItem("googleSSO");
  const group = context.group;

	const dismissPopover = () => {
		document.body.click();
	};

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate({ FRONTEND_LOGIN_URL });
    }
  }, []);

  return (
    <Navbar>
      <NavBarLogo navItemTitle={t('Home')} logo={logo} />
      
      {userPermissions.includes('stories') && showStories && (
      <CenterNavItem
        navItemTitle={t(`${storiesName}`)}
        icon={<MonochromePhotosIcon/>}
        link={STORIES_URL}
        navigate={navigate}
      />
      )}

      {showCategories &&
      <CategoriesNavItem 
        navItemTitle={t(`${categoriesName}`)}
        icon={<SchoolIcon/>} 
        categories={categories} 
        navigate={navigate}
      />
      }

      {enableSchools && 
      <CategoriesNavItem 
        navItemTitle={LIBRARY_PROGRAMS}
        icon={<MenuBookSharpIcon/>} 
        categories={categories} 
        navigate={navigate}
        showPrograms={true}
        activeAccessCodes={activeAccessCodes}
      />
      }

      {enableSchools && 
      <CategoriesNavItem 
        navItemTitle={LIBRARY_RESOURCES}
        icon={<LibraryBooksSharpIcon/>} 
        categories={categories} 
        navigate={navigate}
        showResources={true}
        activeAccessCodes={activeAccessCodes}
      />
      }

      {userPermissions.includes('leaderboards') && showLeaderboards && (
      <CenterNavItem
        navItemTitle={t('Leaderboards')}
        icon={<TableChartIcon/>}
        link={LEADERBOARDS_URL}
        navigate={navigate}
      />
      )}

      {showConnections && (
          <CenterNavItem
              navItemTitle={t('Connect')}
              icon={<WorkspacesSharpIcon/>}
              link={CONNECTIONS_URL}
              navigate={navigate}
          />
      )}

      {showSchoolsUserManagement && userPermissions.includes('admin') && (
          <CenterNavItem
              navItemTitle={t('School Management')}
              icon={<ManageAccountsIcon/>}
              link={EDUCATIONAL_USER_MANAGEMENT_WITHOUTPARAM_URL}
              navigate={navigate}
          />
      )}

      {/*TODO INCLUDE SIMPLE TEACHER*/}
      {showSchoolsReporting && (userPermissions.includes('admin') || userPermissions.includes('teacher')) && (
          <CenterNavItem
              navItemTitle={t('Reporting')}
              icon={<AssessmentIcon/>}
              link={TT_REPORTING}
              navigate={navigate}
          />
      )}




      {(userPermissions.includes('guides') && showUserGuidesInMainNav && showGuides) &&  (
      <CenterNavItem
        navItemTitle={t(`Guides`)}
        icon={<HelpCenterIcon/>}
        link={WELCOME_URL}
        navigate={navigate}
      />
      )}


      {enableSchools && userPermissions.includes('uber') ? (
          <AdminNavItem
              navItemTitle={t('Admin')}
              icon={<AdminPanelSettingsIcon/>}
              group={group}
              navigate={navigate}
              showBillingOptions={(tenantProperties && tenantProperties.showBillingOptions) ? tenantProperties.showBillingOptions : false}
              showCreateAccessCode={(tenantProperties && tenantProperties.showCreateAccessCode) ? tenantProperties.showCreateAccessCode : false}
              showResetLeaderboards={showResetLeaderboards}
              showContentManagement={showContentManagement}
              showReports={showReports}
              showAwardsManagement={showAwardsManagement}
              showCustomizeLykio={showCustomizeLykio}
              showUserManagement={showUserManagement}
              showUberUserManagement={showUberUserManagement}
              showCreateContent={showCreateContent}
              userPermissions={userPermissions}
              userDetails={userDetails}
          />
      ) : !enableSchools && userPermissions.includes('admin') &&
            <AdminNavItem
                navItemTitle={t('Admin')}
                icon={<AdminPanelSettingsIcon/>}
                group={group}
                navigate={navigate}
                showBillingOptions={(tenantProperties && tenantProperties.showBillingOptions) ? tenantProperties.showBillingOptions : false}
                showCreateAccessCode={(tenantProperties && tenantProperties.showCreateAccessCode) ? tenantProperties.showCreateAccessCode : false}
                showResetLeaderboards={showResetLeaderboards}
                showContentManagement={showContentManagement}
                showReports={showReports}
                showAwardsManagement={showAwardsManagement}
                showCustomizeLykio={showCustomizeLykio}
                showUserManagement={showUserManagement}
                showUberUserManagement={showUberUserManagement}
                showCreateContent={showCreateContent}
                userPermissions={userPermissions}
                userDetails={userDetails}
            />

      }

      {userPermissions.includes('createstory') && showStories && (
      <RightNavItem 
      navItemTitle={t('Upload Story')}
      icon={<AddAPhotoSharpIcon/>} 
      leftmargin={true} 
      link={CREATE_URL}
      navigate={navigate}/>
      )}

      {showMessenger &&
      <ChatNavItem
      navItemTitle={t('Chat')}
      icon={<ChatIcon/>}
      link={CHAT_URL}
      userDetails={userDetails}
      leftmargin={!showStories}
      navigate={navigate}/>
      }
      
      <NotificationsNavItem 
      leftmargin={!userPermissions.includes('createstory')} 
      userDetails={userDetails} 
      leftmargin={!showStories && !showMessenger}
      />
      
      {userPermissions.includes('profile') && (
      <ProfileNavItem
          userDetails={userDetails}
          enableSchools = {enableSchools}
      />
      )}

      <MoreOptionsNavItem 
      dismissPopover={dismissPopover}
      navItemTitle={t('Upload Story')} 
      icon={<AppsIcon/>} 
      navigate={navigate}
      userPermissions={userPermissions}
      userDetails={userDetails}
      showIdeology={showIdeology}
      showGuides={showGuides}
      showMessages={showMessages}
      showUserGuidesInMainNav={showUserGuidesInMainNav}
      tenantProperties={tenantProperties}
      enableSchools = {enableSchools}
      />

    </Navbar>
  );
};

function Navbar(props) {
  return (
    <nav className="meta-navbar">
      <ul className="meta-navbar-nav">{props.children}</ul>
    </nav>
  );
}

function RightNavItem(props) {
  const userDetails = props.userDetails;

  return (
    <li className={"meta-nav-item "+ (props.leftmargin && ' meta-auto-left-margin')}>
      <Tooltip title={props.navItemTitle} placement="bottom">
      <a className="meta-icon-button" onClick={() => {
        props.link && props.navigate(props.link);
        }}>
        {props.icon} {userDetails && <ActiveProfileImage user={userDetails.id}/>}
      </a>
      </Tooltip>
    </li>
  );
}

function ChatNavItem (props) {
  const userDetails = props.userDetails;

  return (
      <li className={"meta-nav-item "+ (props.leftmargin && ' meta-auto-left-margin')}>
          <a className="meta-icon-button" onClick={() => props.link && props.navigate(props.link)}>
            <Tooltip title={t("Chat")} placement="left">
              <Badge badgeContent={userDetails && userDetails.chatNotifications} color="primary">
                {props.icon}
              </Badge>
            </Tooltip>
          </a>
      </li>
  );
}

function MoreOptionsNavItem(props) {
  const userPermissions= props.userPermissions;
  const userDetails = props.userDetails
  const tenantProperties = props.tenantProperties;
  const showFeed = tenantProperties && tenantProperties.showFeed;
  const showCollectionsManagement = tenantProperties && tenantProperties.showCollectionsManagement
  const showCollections = tenantProperties && tenantProperties.showCollections
  const enableSchools = props.enableSchools;


  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('showToastNotificationsToken');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('localThemeName');
    localStorage.removeItem('localShowBackgroundImages');
    localStorage.removeItem('localbackgroundImage');
    localStorage.removeItem('localca');
    localStorage.removeItem('userRole');
    localStorage.removeItem('teacherbookActivated');
    localStorage.removeItem('studentbookActivated');
    localStorage.removeItem('workbookActivated');
    localStorage.removeItem('levelIsActivated');
    props.navigate(FRONTEND_LOGIN_URL);
  };

  return <PopupState disableAutoFocus={false} variant="popover" popupId="options-popup-popover">
  {(popupState) => (
    <li className="meta-nav-item">
      <Tooltip title={t('More Options')} placement="bottom">
      <a {...bindTrigger(popupState)} >
        <div className="meta-icon-button">{props.icon}</div>
      </a>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

      <div className="meta-navigation-options-profile">  
      <Typography sx={{ p: 2 }}>
      <a className="lykiocss-meta-anchor meta-navigation-options-profile"
        onClick={() => {
          popupState.close();
          props.navigate(`/profile/${userDetails && userDetails.id}`);
        }}
      >
      <div><AccountCircleSharpIcon/> {t('Profile')}</div>
      </a>
      </Typography>
      </div>

        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(SETTINGS_URL);
          }}
        >
        <div><SettingsIcon/> {t('Settings')}</div>
        </a>
        </Typography>  

        {userPermissions.includes('feed') && showFeed &&(
        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(STORIES_FOLLOWING_URL);
          }}
        >
        <div><PlayLessonIcon/> {t('My Feed')}</div>
        </a>
        </Typography>  
        )}

        {/* {userPermissions.includes('feed') && (
        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(`/progress/${userDetails && userDetails.id}`);
          }}
        >
        <div><ViewTimelineSharpIcon/> {t('My Progress')}</div>
        </a>
        </Typography>  
        )} */}

          {userPermissions.includes('favourites') && showCollectionsManagement && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(PLAYLIST_URL);
            }}
          >
          <div><QueuePlayNextSharpIcon/> {t('Manage Playlists')}</div>
          </a>
          </Typography>
          )}

        {userPermissions.includes('favourites') && showCollections && (
        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(PLAYLISTS_URL);
          }}
        >
        <div><PlaylistPlayIcon/> {t('My Playlists')}</div>
        </a>
        </Typography>
        )}

        {(userPermissions.includes('ideology') && props.showIdeology) && (  
        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(IDEOLOGY_URL);
          }}
        >
        <div><BatchPredictionIcon/> {t('Ideology')}</div>
        </a>
        </Typography>            
        )}

        {(userPermissions.includes('guides') && props.showGuides && !props.showUserGuidesInMainNav) && (
        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(WELCOME_URL);
          }}
        >
        <div><HelpCenterIcon/> {t('Guides')}</div>
        </a>
        </Typography>
        )}

        {userPermissions.includes('search') && !enableSchools && (
        <Typography sx={{ p: 2 }}>
        <a className="lykiocss-meta-anchor"
          onClick={() => {
            popupState.close();
            props.navigate(SEARCH_URL);
          }}
        >
        <div><ContentPasteSearchIcon/> {t('Search')}</div>
        </a>
        </Typography>
        )}

        {(userPermissions.includes('messages') && props.showMessages ) && (
        <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(MESSAGES_URL);
            }}
          >
          <div><ForumIcon/> {t('Messages')}</div>
          </a>
        </Typography>
        )}
        
        <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              logout();
            }}
          >
          <div><LogoutIcon/> {t('Logout')}</div>
          </a>
        </Typography>
      </Popover>
    </li>
  )}
  </PopupState>
}

function NavBarLogo(props) {
  const [open, setOpen] = useState(false);
  return (
    <li style={{ marginRight: "auto" }} className="meta-nav-item">
      <Tooltip title={props.navItemTitle} placement="bottom">

        <Link to="/" className="meta-logo" onClick={() => setOpen(!open)}>
          <Image
            className="lykioccs-navigation-navbar-logo-image lykiocss-transparent-image--border"
            src={props.logo}
            alt=""
          />
        </Link>

      </Tooltip>
      {open && props.children}
    </li>
  );
}

function CenterNavItem(props) {
  return (
    <li className="meta-nav-center-item">
      
      <a className="meta-styled-anchor"
        onClick={() => {
          props.link && props.navigate(props.link);
        }}
      >
        <Tooltip title={props.navItemTitle} placement="bottom">
          <div className="meta-center-icon-button">{props.icon}</div>
        </Tooltip>
        <Button className="meta-center-text-button" variant="text">{props.icon}&nbsp;{props.navItemTitle}</Button>
      </a>
      
    </li>
  );
}

function CategoriesNavItem(props) {
  const showPrograms = props.showPrograms;
  const showResources = props.showResources;
  const activeAccessCodes= props.activeAccessCodes;
  let accessLevels = [];
  if(activeAccessCodes){
    for (let i = 0; i < activeAccessCodes.length; i++){
      accessLevels.push(`Level${activeAccessCodes[i].level}`.toLowerCase());
    }
  }

  if(showPrograms || showResources){
    return <PopupState variant="popover" popupId="options-popup-popover">
    {(popupState) => (
      <li className="meta-nav-center-item">
        <a className="meta-styled-anchor" {...bindTrigger(popupState)} >
          <Tooltip title={props.navItemTitle} placement="bottom">
            <div className="meta-center-icon-button">{props.icon}</div>
          </Tooltip>
          <Button className="meta-center-text-button" variant="text">{props.icon}&nbsp;{props.navItemTitle}</Button>
        </a>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        {(props.categories && props.categories.length>0 && accessLevels.length>0) ?
          props.categories.map((category) => {
            if(accessLevels.includes(category.reference.toLowerCase())){
            return (
              <Typography sx={{ p: 2 }} key={category._id}>
                <a className="lykiocss-meta-anchor"
                  onClick={() => {
                    popupState.close();
                    if(showResources){
                      props.navigate(`${LIBRARY_URL_WITHOUT_PARAM}/${category.reference}/${LIBRARY_RESOURCES}`);
                    }
                    else if(showPrograms){
                      localStorage.removeItem('teacherbookActivated');
                      localStorage.removeItem('studentbookActivated');
                      localStorage.removeItem('workbookActivated');
                      localStorage.removeItem('levelIsActivated');
                      props.navigate(`${LIBRARY_URL_WITHOUT_PARAM}/${category.reference}/${LIBRARY_PROGRAMS}`);
                    }
                    else{
                      props.navigate(`${LIBRARY_URL_WITHOUT_PARAM}/${category.reference}/${LIBRARY_UNITS}`);
                    }
                    
                  }}
                >
                <div>{category.name}</div>
                </a>
              </Typography>
            );
            }
          }) : <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor" href="#"
          >
          <div>{t('No Active Levels')}</div>
          </a>
        </Typography>}
        </Popover>
      </li>
    )}
    </PopupState>
  }else{
    return <PopupState variant="popover" popupId="options-popup-popover">
    {(popupState) => (
      <li className="meta-nav-center-item">
        <a className="meta-styled-anchor" {...bindTrigger(popupState)} >
          <Tooltip title={props.navItemTitle} placement="bottom">
            <div className="meta-center-icon-button">{props.icon}</div>
          </Tooltip>
          <Button className="meta-center-text-button" variant="text">{props.icon}&nbsp;{props.navItemTitle}</Button>
        </a>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        {(props.categories && props.categories.length>0) ?
          props.categories.map((category) => {
            return (
              <Typography sx={{ p: 2 }} key={category._id}>
                <a className="lykiocss-meta-anchor"
                  onClick={() => {
                    popupState.close();
                    props.navigate(`${LIBRARY_URL_WITHOUT_PARAM}/${category.reference}/${LIBRARY_UNITS}`);
                  }}
                >
                <div>{category.name}</div>
                </a>
              </Typography>
            );
          }) : <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor" href="#"
          >
          <div>{t('No Categories Yet')}</div>
          </a>
        </Typography>}
        </Popover>
      </li>
    )}
    </PopupState>
  }

}

function AdminNavItem(props) {
  const userPermissions= props.userPermissions;
  const userDetails = props.userDetails;
  const group = props.group;
  const showContentManagement= props.showContentManagement;
  const showReports= props.showReports;
  const showAwardsManagement= props.showAwardsManagement;
  const showCustomizeLykio= props.showCustomizeLykio;
  const showUserManagement= props.showUserManagement;
  const showUberUserManagement= props.showUberUserManagement;
  const showCreateContent= props.showCreateContent;

  return <PopupState variant="popover" popupId="options-popup-popover">
  {(popupState) => (
    <li className="meta-nav-center-item">
      <a className="meta-styled-anchor" {...bindTrigger(popupState)} >
        <Tooltip title={props.navItemTitle} placement="bottom">
          <div className="meta-center-icon-button">{props.icon}</div>
        </Tooltip>
        <Button className="meta-center-text-button" variant="text">{props.icon}&nbsp;{props.navItemTitle}</Button>
      </a>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {userPermissions.includes('reports') && showReports && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(REPORTS_URL);
            }}
          >
          <div><AssessmentIcon/> {t('Reports')}</div>
          </a>
          </Typography>
        )}

          {(userPermissions.includes('billing') && props.showBillingOptions) && (  
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(STRIPE_PAYMENT_URL);
            }}
          >
          <div><CreditCardIcon/> {t('Billing')}</div>
          </a>
          </Typography>
          )}

          {userPermissions.includes('uber') && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(UBER_ADMIN_SETTINGS_URL);
            }}
          >
          <div><AppSettingsAltIcon/> {t('Uber Settings')}</div>
          </a>
          </Typography>
          )}

          {(userPermissions.includes('customizeplatform') || userPermissions.includes('uber')) && showAwardsManagement && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(AWARD_URL);
            }}
          >
          <div><MilitaryTechSharpIcon/> {t('Add Award')}</div>
          </a>
          </Typography>
          )}

          {(userPermissions.includes('customizeplatform') || userPermissions.includes('uber')) && showCustomizeLykio && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(CUSTOMIZE_LYKIO_URL);
            }}
          >
          <div><StyleIcon/> {t('Customize Lykio')}</div>
          </a>
          </Typography>
          )}

          {((userPermissions.includes('customizeplatform') || userPermissions.includes('uber')) && props.showResetLeaderboards) &&(
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(RESET_LEADERBOARDS_URL);
            }}
          >
          <div><RotateLeftSharpIcon/> {t('Reset Leaderboards')}</div>
          </a>
          </Typography>
          )}

          {((userPermissions.includes('admin') || userPermissions.includes('uber')) && props.showCreateAccessCode) && (  
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(CREATE_ACCESS_CODE_URL);
            }}
          >
          <div><QrCodeIcon/> {t('Create Access Codes')}</div>
          </a>
          </Typography>
          )}

          {userPermissions.includes('categoriesmanagement') && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(CONTENT_MANAGEMENT_CATEGORIES_URL);
            }}
          >
          <div><PlaylistAddIcon/> {t('Add Categories')}</div>
          </a>
          </Typography>
          )}

          {userPermissions.includes('contentmoderation') && showContentManagement && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(`${CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL}/System`);
            }}
          >
          <div><NoteAltIcon/> {t('Content Management')}</div>
          </a>
          </Typography>
          )}

          {userPermissions.includes('usermanagement') && showUserManagement && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(`${USER_MANAGEMENT_WITHOUTPARAM_URL}/${group}`);
            }}
          >
          <div><ManageAccountsIcon/> {t('User Management')}</div>
          </a>
          </Typography>
          )}

          {userPermissions.includes('teacherusermanagement') && showUserManagement && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(`${USER_MANAGEMENT_WITHOUTPARAM_URL}/${userDetails.group.reference}`);
            }}
          >
          <div><ManageAccountsIcon/> {t('User Management')}</div>
          </a>
          </Typography>
          )}

          {/*userPermissions.includes('contentcreation') && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(CONTENT_MANAGEMENT_ADDS3_URL);
            }}
          >
          <div><CloudUploadIcon/> {t('Add Cloud Hosted Content')}</div>
          </a>
          </Typography>
          )*/}

          {userPermissions.includes('contentcreation') && showCreateContent && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              /*props.navigate(CONTENT_MANAGEMENT_CREATE_URL);*/
              props.navigate(CONTENT_CREATION);
            }}
          >
          <div><ArticleIcon/> {t('Create System Content')}</div>
          </a>
          </Typography>
          )} 

          {/*userPermissions.includes('contentcreation') && (
          <Typography sx={{ p: 2 }}>
          <a className="lykiocss-meta-anchor"
            onClick={() => {
              popupState.close();
              props.navigate(UPLOAD_ZIPPED_UNIT_URL);
            }}
          >
          <div><FolderZipSharpIcon/> {t('Upload Zipped SCORM Unit')}</div>
          </a>
          </Typography>
          )*/} 

      </Popover>
    </li>
  )}
  </PopupState>
}

export default MetaNavigation;
