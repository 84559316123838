import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import './home.css';
import { AUTHORIZED_URL_PREFIX, SEARCH_URL, HOME_DATA_URL, LIBRARY_UNITS, AxiosConfig } from '../../Utils/Constants';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import t from '../../Utils/Translation';
import Units from './Units';
import FeaturedStory from './FeaturedStory';
import FeaturedStories from './FeaturedStories';
import { ErrorBoundary } from 'react-error-boundary';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HomeStats from './HomeStats';
import ProfileBox from '../common/ProfileBox';
import EmptyCard from '../common/EmptyCard';
import UnitSplider from '../common/UnitSplider';
import FeaturedStoriesSplider from '../common/FeaturedStoriesSplider';
import first_screen from './../../multimedia/first_screen.png';
import Form from 'react-bootstrap/Form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import ProfileCard from '../common/ProfileCard';
import Story from '../stories/Story';
import SupportEmail from '../common/SupportEmail';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>{t('Something went wrong')}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t('Try again')}</button>
    </div>
  );
};

const Home = () => {
	const context = useContext(LykioContext);
  const userDetails = context.userDetails;
	const tenantProperties = context.tenantProperties;
	const storiesName = tenantProperties && tenantProperties.storiesName ? tenantProperties.storiesName : "Stories";
  const tenantName = tenantProperties && tenantProperties.name ? tenantProperties.name : "Lykio";
  const initiative = (tenantProperties && tenantProperties.initiative) && tenantProperties.initiative;
  const makeHeadersDark = tenantProperties && tenantProperties.makeHeadersDark;
  const [ featuredStories, setFeaturedStories ] = useState([]);
	const [ mostUsed, setMostUsed ] = useState([]);
	const [ topRated, setTopRated ] = useState([]);
	const [ newUnits, setNewUnits ] = useState([]);
	const [numberOfGroups, setNumberOfGroups ] = useState(0);
	const [numberOfActiveUsers, setNumberOfActiveUsers ] = useState(0);
	const [numberOfActiveUnits, setNumberOfActiveUnits ] = useState(0);
	const [numberOfApprovedStories, setNumberOfApprovedStories ] = useState(0);
  const [enableSchools, setEnableSchools] = useState(true);
	const [ isLoading, setIsLoading ] = useState(true);
	const language = localStorage.getItem('content-i18n');
  const navigate = useNavigate();

  const [ searchTerms, setSearchTerms ] = useState([]);
	const [ type, setType ]= useState();
	const [ result, setResult ]= useState();

  const [showResultModal, setShowResultModal] = useState(false);
  const handleCloseResultModal = () => setShowResultModal(false);
  const handleShowResultModal = () => setShowResultModal(true);

	useEffect(() => {
		const fetchSearchData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${SEARCH_URL}`, AxiosConfig());
				setSearchTerms(data.searchTerms);
				if (!data.success) {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchSearchData();
	}, []);

	useEffect(() => {
		const fetchHomeData = async () => {
			try {
				const { data } = await axios.post(`${AUTHORIZED_URL_PREFIX}${HOME_DATA_URL}`,{language}, AxiosConfig());
				setFeaturedStories(data.featuredStories);
				setMostUsed(data.mostUsed ? data.mostUsed : []);
				setNewUnits(data.newUnits ? data.newUnits : []);
				setTopRated(data.topRated ? data.topRated : []);
				setNumberOfGroups(data.numberOfGroups);
				setNumberOfActiveUsers(data.numberOfActiveUsers);
				setNumberOfActiveUnits(data.numberOfActiveUnits);
				setNumberOfApprovedStories(data.numberOfApprovedStories);
        setEnableSchools(data.enableSchools);
				setIsLoading(false);
				if (!data.success) {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchHomeData();
	}, []);

	return (
    <div className="lykioccs-main-content lykiocss-home">
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {enableSchools ? 
          <Container className='tt-background' style={{marginLeft:'0em !important'}}>
            <Row>
              <Col xs={12}>
                {/* <h1 className={`lykiocss-page-title ${makeHeadersDark && 'lykiocss-dark-color'}`}>{initiative ? initiative : tenantName}</h1> */}
              </Col>
            </Row>
          </Container>
          :
          <Container style={{marginLeft:'0em !important'}}>
            <Row>
              <Col xs={12} md={6}>
                <h1 className={`lykiocss-page-title ${makeHeadersDark && 'lykiocss-dark-color'}`}>{initiative ? initiative : tenantName}</h1>
              </Col>
              <Col xs={12} md={6}>
                    <div className='lykiocss-form' style={{marginTop:'4em'}}>
                    <Form.Group controlId="title">
                    <Autocomplete
                    disablePortal
                    id="combo-box-searchTerms"
                    options={searchTerms}
                    onChange={(event, value) => {
                      console.log(value ? value.item.type : undefined)
                      setType(value ? value.item.type : undefined)
                      setResult(value ? value.item.result : undefined)
                      value && value.item.result && handleShowResultModal()
                    }	
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={`🔎 ${t("Search for lessons, stories and users")}`} />
                    )}
                    />
                    </Form.Group>
                    </div>

                    <Modal show={showResultModal} onHide={handleCloseResultModal}>
                      <Modal.Body>
                      <Result type={type} result={result} />
                      </Modal.Body>
                    </Modal>

              </Col>
            </Row>
            <Row style={{marginTop:'1em'}}>
                <Col xs={12}>
                  <Row className="lykiocss-home-stats-wrapper">
                    {(userDetails && userDetails.settings.dashboard.showProgress) && (
                      <Col className="lykiocss-home-stats lykiocss-home-stats1 lykiocss-margin-top-half-em">
                        {/* <EmptyCard title="Progress" destination={`/profile/${userDetails && userDetails.id}`} destinationTitle="Profile"> */}
                        <EmptyCard>
                          <ProfileBox userDetails={userDetails} />
                        </EmptyCard>
                      </Col>
                    )}

                  {(userDetails && userDetails.settings.dashboard.showProgress) && (
                      <Col onClick={() => {
                        navigate(`/profile/${userDetails && userDetails.id}`);
                      }} className="lykiocss-home-stats lykiocss-home-stats2 lykiocss-margin-top-half-em" style={{cursor: 'pointer'}}>
                        <EmptyCard title={t(`Your Achievements`)}>
                          <ProfileBox userDetails={userDetails} />
                        </EmptyCard>
                      </Col>
                    )}

                    {userDetails && userDetails.settings.dashboard.showHomeStats && (
                      <Col className="lykiocss-home-stats lykiocss-home-stats3 lykiocss-margin-top-half-em">
                        {/* <HomeStats numberOfGroups={numberOfGroups} numberOfActiveUsers={numberOfActiveUsers} numberOfActiveUnits={numberOfActiveUnits} numberOfApprovedStories={numberOfApprovedStories}/> */}
                        <EmptyCard title={t("Platform at a glance")}>
                          <HomeStats numberOfGroups={numberOfGroups} numberOfActiveUsers={numberOfActiveUsers} numberOfActiveUnits={numberOfActiveUnits} numberOfApprovedStories={numberOfApprovedStories}/>
                        </EmptyCard>  
                      </Col>
                    )}
                  </Row>
                </Col> 
              </Row>
          </Container>
          }

          {featuredStories.length > 0 && userDetails && userDetails.settings.dashboard.showFeatured && (
          <div style={{marginTop:'1em', marginLeft:'1em'}}>
            <Units title={`${t("Featured")} ${storiesName}`} units={featuredStories} makeHeadersDark={makeHeadersDark}/>
          </div>
          )}

          {/* <div style={{marginTop:'1em', marginLeft:'1em'}}>
          <Units title={t("New Units")} units={newUnits} makeHeadersDark={makeHeadersDark}/>
          </div> */}

          {!enableSchools &&
          <React.Fragment>
            <div style={{marginTop:'1em', marginLeft:'1em'}}>
              <Units libraryType={LIBRARY_UNITS} title={t("Most Used")} units={mostUsed} makeHeadersDark={makeHeadersDark}/>
            </div>
            <div style={{marginTop:'1em', marginLeft:'1em'}}>
              <Units libraryType={LIBRARY_UNITS} title={t("Top Rated")} units={topRated} makeHeadersDark={makeHeadersDark}/>
            </div>
          </React.Fragment>
          }
          </React.Fragment>
      )}
      {enableSchools && <SupportEmail/>}
    </div>
  );
};

const Result = (props)=>{
	const type= props.type;
	const result=props.result;

	return (<div>
		{(type==='story' || type==='unit') && <Story story={result} />}
		{type==='user' && <ProfileCard user={result} showName={true}/>}
		</div>
	)
}

export default Home;
